<template>
  <div class="common">
    <h6>发放卡券</h6>
    <el-button link type="primary" class="backall" @click="couponCancel"
      >返回上一级</el-button
    >
    <el-form :model="form" label-width="120px">
      <el-form-item label="券ID："
        ><p>{{ formDetails.code }}</p>
      </el-form-item>
      <el-form-item label="优惠券名称：">
        <p>{{ formDetails.name }}</p>
      </el-form-item>
      <el-form-item label="优惠券内容：">
        <p>{{ formDetails.content }}</p>
      </el-form-item>
      <el-form-item label="使用范围：">
        <p>{{ useGoodsTypeText(formDetails.useGoodsType) }}</p>
      </el-form-item>
      <el-form-item label="领券时间：">
        <p v-if="formDetails.getTimeStart">
          {{ formDetails.getTimeStart + '~' + formDetails.getTimeEnd }}
        </p>
      </el-form-item>
      <el-form-item label="领券次数：">
        <p v-if="formDetails.getNumType == 1">不限制</p>
        <p v-if="formDetails.getNum">每人限{{ formDetails.getNum }}张</p>
      </el-form-item>
      <el-form-item label="用券时间：">
        <p v-if="formDetails.useTime">
          领券起，{{ formDetails.useTime }}日内可用
        </p>
      </el-form-item>
      <el-form-item label="发放数量：">
        <p v-if="formDetails.sendTotal">{{ formDetails.sendTotal }}张</p>
      </el-form-item>
      <el-form-item label="已发放：">
        <p>{{ formDetails.receivedNum }}张</p>
      </el-form-item>
      <el-form-item label="已使用：">
        <p>{{ formDetails.usedNum }}张</p>
      </el-form-item>
      <el-form-item label="使用率：">
        <p v-if="formDetails.usedNum > 0 && formDetails.receivedNum > 0">
          {{ (formDetails.usedNum / formDetails.receivedNum) * 100 + '%' }}
        </p>
        <p v-else>0</p>
      </el-form-item>
      <h6>发放设置</h6>
      <el-form-item label="每人发放">
        <el-input
          placeholder="请输入"
          v-model="form.everyNum"
          @keyup="numberInput"
        />张
      </el-form-item>
      <el-form-item label="预计发放人数："> {{ form.issued }}人 </el-form-item>
      <el-form-item label="发放范围">
        <el-button type="primary" @click="getUser">选择用户</el-button>
      </el-form-item>
      <el-form-item label="" v-if="rTableData.length > 0">
        <TableView
          :columns="columns"
          :data="rTableData"
          :is-pagination-show="false"
          wrapperHeight="450"
        >
          <template #avatarUrl>
            <el-table-column label="头像" align="center">
              <template #default="scoped">
                <div class="desc">
                  <img
                    :src="
                      scoped.row.avatarUrl
                        ? scoped.row.avatarUrl
                        : require('../../assets/img/logo.jpg')
                    "
                  />
                </div>
              </template>
            </el-table-column>
          </template>
        </TableView>
      </el-form-item>
      <div style="text-align: center">
        <el-button @click="couponCancel">取消</el-button>
        <el-button type="primary" @click="submitCouponDistribution">
          确认发放
        </el-button>
      </div>
    </el-form>
    <el-dialog
      v-model="userVisible"
      title="选择用户"
      width="800px"
      :before-close="getUserClose"
      top="10vh"
    >
      <el-form :model="form" label-width="140px">
        <el-form-item label="发放维度：">
          <el-select
            placeholder="请选择"
            v-model="form.latitude"
            @change="handleChangeLatitude"
          >
            <el-option
              v-for="item in latitudeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用户信息：" v-if="form.latitude == 1">
          <el-input placeholder="请输入" v-model="form.userInfo" clearable />
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
        <el-form-item label="按excel名单发放：" v-if="form.latitude == 2">
          <el-upload
            class="upload-demo"
            ref="upload"
            action="https://www.hxupan.cn/zrwp/api/coupon/uploadExcel"
            :limit="1"
            :on-success="successfile"
          >
            <el-button type="primary" @click="handleClick">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="按用户等级：" v-if="form.latitude == 3">
          <el-select placeholder="请选择" v-model="form.level">
            <el-option
              v-for="item in levelLimitList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template v-if="form.latitude == 1">
        <el-table
          v-if="userTableData.length > 0"
          v-loading="userLoading"
          ref="multipleTable"
          :data="userTableData"
          height="400"
          style="width: 100%"
        >
          <el-table-column label="选择" width="60">
            <template #header>
              <el-checkbox v-model="selectAll"></el-checkbox>
            </template>
            <template #default="{ row }">
              <div class="flex row-center" @click.stop>
                <el-checkbox
                  :model-value="selectItem(row)"
                  @change="handleSelect($event, row)"
                ></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="用户头像" align="center">
            <template #default="scoped">
              <div class="desc">
                <img
                  :src="
                    scoped.row.avatarUrl
                      ? scoped.row.avatarUrl
                      : require('../../assets/img/logo.jpg')
                  "
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="昵称" align="center" prop="nickName">
          </el-table-column>
          <el-table-column label="手机号" align="center" prop="phone">
          </el-table-column>
          <el-table-column label="会员等级" align="center" prop="">
          </el-table-column>
          <el-table-column label="注册时间" align="center" prop="createTime">
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          v-if="userPagination.total > 0"
          :current-page="userPagination.current"
          :page-size="userPagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="userPagination.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="getUserClose"> 取消 </el-button>
          <el-button type="primary" @click="onSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, computed, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import { ElMessage } from 'element-plus'
import { guestPage } from '@/api/userManagement/user'
import {
  couponDetail,
  couponDistribution,
  queryCouponDistribution
} from '@/api/cardManagement/platform'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const upload = ref(null)
const initData = reactive({
  form: {
    latitude: 1,
    userInfo: '',
    file: [],
    issued: 0,
    everyNum: '',
    level: ''
  },
  // 选择商品弹窗数据
  userVisible: false,
  userLoading: false,
  userTableData: [],
  userPagination: {
    current: 1,
    size: 10,
    total: 0
  },
  latitudeList: [
    {
      label: '按条件筛选用户',
      value: 1
    },
    {
      label: '按excel名单',
      value: 2
    },
    {
      label: '按用户等级',
      value: 3
    }
  ],
  levelLimitList: [
    {
      label: 'Lv0',
      value: 0
    },
    {
      label: 'Lv1',
      value: 1
    },
    {
      label: 'Lv2',
      value: 2
    },
    {
      label: 'Lv3',
      value: 3
    },
    {
      label: 'Lv4',
      value: 4
    }
  ],
  columns: [
    { slot: 'avatarUrl' },
    { prop: 'nickName', label: '昵称' },
    { prop: 'phone', label: '手机号' },
    { prop: 'info', label: '会员等级' },
    { prop: 'createTime', label: '注册时间' }
  ],
  rTableData: [],
  changeCurrentData: [],
  userPhones: [],
  formDetails: {
    id: '',
    name: '',
    type: 1,
    sendTotal: '',
    sendTotalType: 2,
    sendTotalTypeFlag: false,
    useGoodsType: 1,
    conditionType: 1,
    conditionMoney: '',
    money: '',
    activityIds: [],
    content: '',
    externalId: '',
    getType: 1,
    getTime: [],
    getTimeStart: '',
    getTimeEnd: '',
    useTimeArr: [],
    useTimeStart: '',
    useTimeEnd: '',
    getNumType: 1,
    useTimeType: 1
  },
  flieList: []
})

const {
  form,
  userVisible,
  userLoading,
  userTableData,
  userPagination,
  latitudeList,
  changeCurrentData,
  rTableData,
  columns,
  userPhones,
  formDetails,
  levelLimitList
} = toRefs(initData)

onMounted(() => {
  if (route.query.id) {
    getData(route.query.id)
  }
})

const getData = id => {
  couponDetail({ id: id }).then(({ data: res }) => {
    if (res.code == 200) {
      formDetails.value = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 正整数限制
const numberInput = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

// 使用范围
const useGoodsTypeText = val => {
  let vals = ''
  switch (val) {
    case 1:
      vals = '全部通用'
      break
    case 2:
      vals = '指定商品可用'
      break
    case 3:
      vals = '指定商品不可用'
      break
  }
  return vals
}

// 发券纬度
const handleChangeLatitude = () => {
  userPagination.value.current = 1
  userPagination.value.size = 10
  userPagination.value.total = 0
  userTableData.value = []
  form.value.file = []
  changeCurrentData.value = []
  rTableData.value = []
  form.value.userInfo = ''
  form.value.level = ''
  if (form.value.latitude == 1) {
    getDataList()
  }
}

// 选择用户
const getUser = () => {
  getDataList()
  userVisible.value = true
}

// 上传成功
const successfile = res => {
  if (res.code == 200) {
    userPhones.value = res.data
    ElMessage.success(res.msg)
  } else {
    ElMessage.error(res.msg)
  }
}

// 覆盖之前文件
const handleClick = () => {
  upload.value.clearFiles()
}

// 取消
const couponCancel = () => {
  router.push({
    path: '/platform'
  })
}
// 确认发放
const submitCouponDistribution = () => {
  if (form.value.issued == 0) {
    ElMessage.warning('请选择发放用户')
    return false
  }
  if (form.value.everyNum == '') {
    ElMessage.warning('请输入每人发放张数')
    return false
  }
  if (form.value.latitude == 1 && rTableData.value.length == 0) {
    ElMessage.warning('请选择发放用户')
    return false
  }
  let data = {
    couponNum: form.value.everyNum,
    couponId: formDetails.value.id
  }
  if (form.value.latitude == 1) {
    data.userIds = rTableData.value.map(item => item.id)
  }
  if (form.value.latitude == 2) {
    data.userPhones = userPhones.value
  }
  if (form.value.latitude == 3) {
    data.level = form.value.level
  }
  couponDistribution(data).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      setTimeout(() => {
        router.push('/platform')
      }, 1000)
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 弹窗确认
const onSubmit = () => {
  const data = {}
  if (form.value.latitude == 1) {
    rTableData.value = []
    if (changeCurrentData.value.length == 0) {
      ElMessage.warning('请选择卡券商品')
      return false
    }
    if (changeCurrentData.value.length <= 25) {
      changeCurrentData.value.forEach(item => {
        rTableData.value.push(item)
      })
      data.userIds = rTableData.value.map(item => item.id)
    } else {
      ElMessage.warning('最多支持选择25件已上架的商品')
    }
  }
  if (form.value.latitude == 2) {
    if (userPhones.value.length == 0) {
      ElMessage.warning('请上传有效名单')
      return false
    }
    if (userPhones.value == '') {
      ElMessage.warning('请选择发放用户')
      return false
    }
    data.userPhones = userPhones.value
  }
  if (form.value.latitude == 3) {
    if (form.value.level == '' && form.value.level != 0) {
      ElMessage.warning('请选择发放用户')
      return false
    }
    data.level = form.value.level
  }
  queryCouponDistribution(data).then(({ data: res }) => {
    if (res.code == 200) {
      userVisible.value = false
      form.value.issued = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const selectAll = computed({
  get: () => {
    if (!changeCurrentData.value) return false
    const ids = changeCurrentData.value.map(item => item.id)
    if (!userTableData.value.length) {
      return false
    }
    return userTableData.value.every(item => ids.includes(item.id))
  },
  set: val => {
    if (val) {
      for (let i = 0; i < userTableData.value.length; i++) {
        const item = userTableData.value[i]
        const ids = changeCurrentData.value.map(item => item.id)
        if (!ids.includes(item.id) && changeCurrentData.value.length < 25) {
          changeCurrentData.value.push(item)
        }
      }
    } else {
      userTableData.value.forEach(row => {
        deleteSelectedData(row)
      })
    }
  }
})

const selectItem = computed(() => {
  return row => {
    if (!changeCurrentData.value) return false
    return changeCurrentData.value.some(item => item.id == row.id)
  }
})

const handleSelect = ($event, row) => {
  if ($event && changeCurrentData.value.length < 25) {
    changeCurrentData.value.push(row)
  } else {
    deleteSelectedData(row)
  }
}

const deleteSelectedData = row => {
  const index = changeCurrentData.value.findIndex(item => item.id == row.id)
  if (index != -1) {
    changeCurrentData?.value.splice(index, 1)
  }
}
// 选择商品弹窗
const getUserClose = () => {
  userVisible.value = false
  if (form.value.latitude == 1) {
    const ids = rTableData.value.map(item => item.id)
    const cus = changeCurrentData.value.map(item => item.id)
    if (ids.length > cus.length) {
      let item = rTableData.value.filter(itemB => !cus.includes(itemB.id))
      changeCurrentData.value.push(...item)
    } else {
      changeCurrentData.value = changeCurrentData.value.filter(itemB =>
        ids.includes(itemB.id)
      )
    }
  } else {
    form.value.userInfo = ''
    form.value.latitude = 1
    form.value.issued = 0
  }
}

// 查询
const search = () => {
  userPagination.value.current = 1
  userPagination.value.size = 10
  getDataList()
}

const handleSizeChange = val => {
  userPagination.value.current = 1
  userPagination.value.size = val
  getDataList()
}

const handleCurrentChange = val => {
  userPagination.value.current = val
  getDataList()
}

// 获取列表数据
const getDataList = val => {
  userLoading.value = true
  const data = {
    current: userPagination.value.current,
    size: userPagination.value.size
  }
  if (form.value.userInfo !== '') {
    data.word = form.value.userInfo
  }
  if (val) {
    data.level = form.value.level
  }
  guestPage(data).then(({ data: res }) => {
    userLoading.value = false
    if (res.code == 200) {
      res.data.records.forEach(item => {
        item.stateName = item.banState == 1 ? '封号' : '正常'
        item.newUserName = item.newUser ? '是' : '否'
      })
      userTableData.value = res.data.records
      userPagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      userTableData.value = []
      userPagination.value.total = 0
    }
  })
}
</script>
<style lang="scss" scoped>
.common {
  h6 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  :deep(.el-form) {
    .form_date {
      display: inline-flex;
    }
    .el-input,
    .el-select {
      width: 200px;
      margin-right: 10px;
    }
    .el-date-editor {
      width: 400px;
    }
    .tables {
      width: 800px;
    }
    .mb-0 {
      margin-bottom: 0;
    }
  }
}
.desc {
  img {
    width: 50px;
    height: 50px;
  }
}
.backall {
  position: absolute;
  top: 10px;
  right: 20px;
}
</style>
